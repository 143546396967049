@import "vendor/normalize";
@import "vendor/bourbon/bourbon";
@import "vendor/neat/neat";
@import "variables";
@import "vendor/typography";
@import "vendor/font-awesome/scss/font-awesome";
@import "vendor/ionicon/ionicons";
@import "theme/mixins";
@import "theme/components/base";
@import "theme/head";
@import "theme/foot";
@import "theme/sidebar";
@import "theme/content/base";
@import "theme/content/single-product";
@import "theme/content/shop-page";

* {
  margin: 0;
  padding: 0;
  outline: none;
}

body,
html {
  margin: 0;
  padding: 0;
  font-size: $em-base;
  line-height: $base-line-height;
  color: nth($color, 7);

  @include media($brMobile) {
    font-size: 14px;
  }
}

body {
  background: $footerBg;

  &.mobile-nav-on {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

// HEADINGS
h1 {
  font-size: 2.5em;
  line-height: 1.1;
}

h2 {
  font-size: 2em;
  line-height: 1.25;
}

h3 {
  font-size: 1.5em;
  line-height: 1.25;
}

h4 {
  font-size: 1em;
  line-height: 1.5;
}

h5 {
  font-size: 0.88em;
}

h6 {
  font-size: 0.66em;
}

address {
  font-style: normal;
}

ol,
ul {
  list-style: none;
}

// LINK
a {
  color: nth($color, 1);
  text-decoration: none;
  .the-content .content &,
  .post-content & {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  &:hover {
    color: nth($color, 7);
    &.unstyled {
      text-decoration: none;
    }
    button {
      text-decoration: none;
    }
  }
}

/* LAYOUT
================================================================================================ */

.wrap {
  @include outer-container($max-width);
  position: relative;

  @include media($brDesktop) {
  }

  @include media($brLargeDesktop) {
    width: auto;
    margin-left: $margin;
    margin-right: $margin;
  }
}

.the-content {
  @include layout(
    300px,
    (
      $brDesktop: 240px
    )
  );

  background: $mainBg;
}

/*! PLEASE DON'T EDIT THIS FILE. EDIT STYLE-ADDITIONAL.CSS INSTEAD */
