/* GENERAL SETTINGS
================================================================================================ */

/* GRAVITY FORM SETTINGS
================================================================================================ */
body {
    div.gform_wrapper,
    div.gform_wrapper.gform_validation_error {
        max-width: none;
        margin: 0;
        overflow: hidden;

        &.gf_browser_chrome {
            .gform_body {
                width: auto;
            }
        }

        textarea {
            padding-top: .5em;
            padding-bottom: .5em;
        }

        ul.gform_fields {
            padding-bottom: 0;

            li.gfield {
                margin: 0;
                padding: 10px 0 0 0;
                background: none;
                &:before {
                    display: none;
                }
                &.gfield_error {
                    div.ginput_container {
                        #{$all-text-inputs} {
                            color: #fff;
                            background: #ff4a35;
                        }
                    }
                }

                @for $i from 1 through 12 {
                    &.col-#{$i} {
                        @include span-columns($i);
                        &.last {
                            margin-right: 0;
                            + li.gfield {
                                clear: left;
                            }
                        }

                        @include media($brMobile) {
                            @include fill-parent;
                        }
                    }
                }

                &.gform_hidden {
                    display: none;
                }

                .ginput_complex {
                    @include clearfix;
                    .name_first,
                    .name_last {
                        @include span-columns(6);
                        @include omega(2n);
                        display: block;
                    }

                    @include media($brMobile) {
                        .name_first,
                        .name_last {
                            @include fill-parent;
                        }
                        .name_last {
                            padding-top: 10px;
                        }
                    }
                }

                label.gfield_label {
                    margin-bottom: .2em;
                    font-weight: 700;
                    display: block;
                }
                div.ginput_container {
                    margin: 0;
                    padding: 0;

                    #{$all-text-inputs} {
                        display: block;
                        width: 100%;
                        margin: 0;
                        text-indent: 0;
                    }

                    textarea {
                        height: auto;
                    }

                    &.ginput_complex {
                        input,
                        select {
                            & + label {
                                display: none;
                            }
                        }
                        span[class^=ginput_] {
                            display: inline-block;
                            margin-bottom: 10px;

                            &.ginput_full {
                                width: 100%;
                                input[type="text"] {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .gform_body {
            width: auto;
        }

        .gform_footer {
            padding: rem(20) 0 0;
        }

        .gform_validation_container {
            display: none;
        }

        div.validation_error {
            width: 100%;
            margin-bottom: 10px;
            padding: 10px;
            font-size: 14px;
            background: #f6c9cc;
            border: none;
        }

        .validation_message {
            display: none;
        }

        .gform_confirmation_message {
            padding: 10px;
            background: #d1f3c0;
        }

        .recaptchatable {
            input[type="text"] {
                height: auto;
            }
        }

    }

    .top_label {
        .gfield_label {
            display: block;
            margin: 0 0;
            font-style: normal;
        }
    }

    label.gfield_label {
        & + div.ginput_container {
            margin: 0 0 10px;
            input,
            select,
            textarea {
                width: 100%;
            }
        }
    }

}

body {
    select {
        @extend input,[type="text"];
        width: 100%;
        margin-bottom: 0.75em;
    }

    #{$all-text-inputs},
    select {
        height: rem(40);
    }

    #{$all-text-inputs} {
        padding: 0 5px;
    }

    #{$all-text-inputs},
    select[multiple=multiple],
    .select2-container .select2-choice,
    textarea {
        background-color: #fff;
        border: 1px solid nth($color, 10);
        border-bottom-width: 3px;

        &:hover {
            border-bottom-color: nth($color, 9);
        }

        &:focus {
            border-bottom-color: nth($color, 1);
            outline: none;
        }
    }

    .select2-container .select2-choice {
        height: 40px;
        line-height: 40px;
    }

    fieldset {
        #{$all-text-inputs},
        select[multiple=multiple],
        .select2-container .select2-choice,
        textarea {
            background: #ffffff;
        }
    }

    input[type=number] {
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}