/* FOOTER
================================================================================================ */
.the-footer {
    position: relative;
    z-index: 3;
    color: #fff;

    a {
        color: #fff;
        &:hover {
            color: nth($color, 4);
        }
    }

    .top {
        padding: rem(30) 0;
        background: nth($color, 1);


        .cols {
            @include display(flex);
            @include justify-content(space-between);
            @include flex-wrap(nowrap);

            .col {
                @include flex(0 1 25%);
                margin-right: 40px;
                &:last-child {
                    margin-right: 0;
                    text-align: right;
                }
            }
        }

        h4 {
            text-transform: uppercase;
            color: #fff;
        }


        ul {
            font-size: rem(14);
            > li {
                padding: 5px 0;

                a {
                    display: block;
                    line-height: 1;
                }

                ul {
                    padding: 5px 0 0 10px;
                    li {
                        padding: 5px 0;
                    }
                }
            }
        }

        .location {
            margin-bottom: 1em;
            line-height: 26px;
            .phone {
                a {
                }
            }
        }

        .social-networks {
            a {
                position: relative;
                z-index: 1;
                width: rem(34);
                height: rem(34);
                border: 1px solid #fff;
                border-radius: 50%;
                &:hover {
                    background: #fff;
                    i {
                        color: nth($color, 1);
                        opacity: 1;
                    }
                }
                i {
                    @include align-center-vh(2);
                    font-size: rem(18);
                }
            }
        }


    }


    .bottom {
        padding: 20px 0;

        .copyright {
            font-size: rem(14);
            text-align: center;
            a {
                text-decoration: none;
            }
            p {
            }
        }

        .social-networks {
            text-align: right;
            a {
                margin: 0 0 0 15px;
                &:hover {
                    i {
                        opacity: 1;
                    }
                }
                i {
                    opacity: .25;
                }
            }
        }


    }

    @include media($brDesktop) {
        .top {
            .cols {
                .col {
                }
            }
        }
    }
    @include media($brTablet) {

        .top {
            padding: 50px 0;
            text-align: center;

            .cols {
                @include display(block);
                .col {
                    margin: 0 0 20px;
                    &:last-child {
                        text-align: center;
                        margin-bottom: 0;
                    }
                }
            }

        }


        .bottom {
            .cols {
                .col {
                    float: none;
                    width: auto;
                    text-align: center;
                    &.c1 {
                        margin-bottom: 20px;
                    }
                }
            }
            .social-networks {
                text-align: center;
                a {
                    margin: 0 10px;
                }

            }
        }
    }


    @include media($brMobile) {


    }
}