@mixin layout($sbWidthDefault, $breakpoints) {

    .flex {
        @include display(flex);
        .main {
            @include flex(1 1 auto);
            @include order(2);
            position: relative;

            &.full {
                @include flex(0 1 100%);
                padding-right: 0;
            }
        }

        .sidebar {
            @include flex(0 0 $sbWidthDefault);
            @include order(1);
        }
    }

    .main {
        padding: rem(80) rem(60);
    }

    @each $br, $width in $breakpoints {
        @include media($br) {
            .flex {
                .sidebar {
                    @include flex(0 0 $width);
                }

                .main {
                    padding-right: 40px;
                    &.full {
                        padding-right: 0;
                    }
                }
            }

        }
    }

    @include media($brLargeDesktop) {
        .sidebar {
        }
    }

    @include media($brDesktop) {

        .flex {
            .main {
            }
            .sidebar {
            }
        }
    }

    @include media($brTablet) {

        .flex {
            @include display(block);
            .main {
                float: none;
                width: auto;
                padding: 40px 0;
            }
            .sidebar {
                float: none;
                width: auto;
                margin: 0;
            }
        }


    }
}

@mixin header($height, $breakpoints) {
    $adminBarHeight: (
        $brLargeDesktop: 32px,
        $brDesktop: 32px,
        $brTablet: 45px,
        $brMobile: 45px
    );

    .the-header {
        height: $height;
        /*.nav-header {
            .menu {
                ul.children,
                ul.sub-menu {
                    top: $height;
                }
            }

        }*/
    }

    #the-container {
        margin-top: $height;
    }

    #mobile-nav {
        top: $height;
    }


    body.admin-bar,
    .admin-bar {
        .the-header {
            top: 32px;
        }

        #mobile-nav {
            top: 32px + $height;
        }

        @include media($brTablet) {
            .the-header {
                top: 45px;
            }
        }
    }

    @each $br, $height in $breakpoints {
        @include media($br) {
            .the-header {
                height: $height;
            }

            #mobile-nav {
                top: $height;
            }

            #the-container {
                margin-top: $height;
            }

            body.admin-bar,
            body.admin-bar.scrolled,
            .admin-bar,
            .admin-bar.scrolled {
                #mobile-nav {
                    top: $height + map_get($adminBarHeight, $br);
                }
            }
        }
    }
}

@mixin align-center-v($zindex: 1) {
    @include transform(translateY(-50%));
    position: absolute;
    z-index: $zindex;
    top: 50%;
}
@mixin align-center-h($zindex: 1) {
    @include transform(translateX(-50%));
    position: absolute;
    z-index: $zindex;
    left: 50%;
}

@mixin align-center-vh($zindex: 1) {
    @include transform(translate(-50%, -50%));
    position: absolute;
    z-index: $zindex;
    top: 50%;
    left: 50%;
}

@mixin rel-height($height) {
    position: relative;
    &:before {
        content: '';
        display: block;
        padding-top: $height;
    }
}

@mixin omega-reset($nth) {
    &:nth-child(#{$nth}) {
        margin-right: flex-gutter();
    }
    &:nth-child(#{$nth}+1) {
        clear: none
    }
}

@mixin cover() {
    background: no-repeat center;
    -webkit-background-size: cover;
    background-size: cover;
}

@mixin bullet-list($bullet-size: rem(16px), $bullet-color: nth($color, 1), $top: rem(12px), $icon: "\f21b") {
    position: relative;
    padding-left: 1.2em;

    &:before {
        position: absolute;
        top: $top;
        left: 0;
        display: block;
        content: $icon;
        font-family: "FontAwesome", sans-serif;
        font-size: $bullet-size;
        color: $bullet-color;
        text-shadow: none;
        line-height: 1;
    }
}

@mixin icon-heading($icon-width: 38px, $position: relative) {
    position: $position;
    padding-left: $icon-width + 12px;

    .icon {
        @include transform(translateY(-50%));
        position: absolute;
        top: 50%;
        left: 0;
        max-width: $icon-width;
        vertical-align: middle;
    }
}

@mixin icon-button($text-dir, $size: 56px, $color: nth($color, 7)) {
    @include clearfix;
    display: inline-block;
    border: 2px solid $color;
    color: $color;

    &:hover {
        text-decoration: none;
        background: $color;
        i, span {
            color: #ffffff;
        }
        i {
            border-color: #666;
        }
    }

    i, span {
        float: left;
        display: block;
        line-height: $size;
    }
    i {
        width: $size;
        text-align: center;
        border-#{$text-dir}: 2px solid $color;
    }
    span {
        padding-left: 1em;
        padding-right: 1em;
        font-weight: 700;
        text-transform: uppercase;
    }
}

@mixin heading-underline($width: 50px, $color: nth($color, 4), $align: left) {
    position: relative;

    &:after {
        position: absolute;
        display: block;
        width: $width;
        height: 1px;
        margin-top: .25em;
        background: $color;
        content: '';

        @if $align == center {
            @include transform(translateX(-50%));
            left: 50%;
        }
    }
}



/* BUTTONS
================================================================================================ */
@mixin btn-style() {
    @include transition(background .3s);

    display: inline-block;
    height: rem(40);
    padding: 0 20px;
    border: 0;
    font-size: rem(14);
    font-weight: 700;
    font-family: $base-font-family;
    color: $mainBg;
    text-decoration: none;
    cursor: pointer;
    background: nth($color, 1);
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: $base-border-radius;

    &:hover,
    &:active {
        background-color: nth($color, 2);
        text-decoration: none;
    }
    &.disabled {
        background-color: transparent;
    }
    &.alt {
        background: nth($color, 3);
        &:hover {
            background-color: nth($color, 4);
        }
    }
    &.strong {
        background-color: nth($color, 5);
        &:hover {
            background-color: nth($color, 6);
        }
    }
    &.ghost {
        color: nth($color, 7);
        border: 2px solid nth($color, 7);
        background-color: transparent;
        
        &:hover {
            color: $mainBg;
            background: nth($color, 7);
        }
        &.alt {
            color: $mainBg;
            border-color: $mainBg;

            &:hover {
                color: nth($color, 1);
                background: $mainBg;
            }
        }
    }
    &.big {
        height: 60px;
        line-height: 58px;
        &:hover {
            color: #fff;
        }
    }
    &.huge {
        height: 72px;
        line-height: 60px;
        font-size: 1.1em;
        &:hover {
            color: #fff;
        }
    }
    &.maxi {
        height: auto;
        font-size: 36px;
        padding: .5em 1em;
        &:hover {
            color: #fff;
        }
    }

    &.naked {
        color: nth($color, 1);
        border: 0;
        background: none;
    }

    &.shadow {
        box-shadow: 0 3px 5px rgba(#000, .25);
    }

    i {
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        font-size: 20px;
    }

    @include media($brMobile) {
        &.huge {
            height: 60px;
            line-height: 58px;
        }

        &.big {
            height: 45px;
            line-height: 45px;
        }

        &.big,
        &.huge {
            font-size: 14px;
        }

    }
}

#{$all-buttons} {
    @include btn-style();
}