#breadcrumbs {
    font-size: 14px;
    color: #ffffff;

    .wrap {
    }

    .post {
        &:after {
            display: none;
        }
    }

    span[typeof="ListItem"] {
        display: inline-block;
        vertical-align: middle;

        a {
            color: nth($color, 1);

            &:hover,
            &:active {
                text-decoration: underline;
            }
        }
    }

    @include media($brTablet) {
        font-size: 10px;
    }
}