.dialog {
    @include align-center-vh(1000);
    position: fixed;
    display: none;
    max-width: 700px;
    width: 80%;
    max-height: 100%;
    padding: 40px;
    background: #fff;
    overflow: auto;

    &.active {
        display: block;
    }

    &#job-application-dialog {
        .content {
            .gform_wrapper {
                label.gfield_label {
                    display: none;
                }
            }
        }
    }

    header {
        margin: 0 0 1em 0;
        position: relative;
        h3 {
            margin: 0;
            padding: 0;
            text-transform: uppercase;
        }

        a.close {
            @include align-center-v(2);
            right: 0;
            color: nth($color, 7);
            &:hover,
            &:active {
                i {
                    color: nth($color, 4);
                }
            }

            i {
                font-size: 28px;
            }
        }
    }


    .content {
        .loader {
            &:before {
                display: none !important;
            }
            text-align: center;
        }
    }
    @include media($brTablet) {
        width: 95%;
        padding: 20px;
    }
}

#dialog-overlay {
    display: none;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000, .9);
    &.active {
        display: block;
    }
}