.post-content {
    ul.tabs {
        @include clearfix;
        margin: 0;
        padding: 0;
        border-bottom: 3px solid nth($color, 9);
        text-transform: uppercase;
        li {
            float: left;
            display: block;
            padding: 0;
            margin: 0;

            &:before {
                display: none;
            }

            a {
                display: block;
                padding: 0 1em;
                font-size: .88em;
                line-height: 35px;
                color: nth($color, 7);
                &:hover {
                    text-decoration: none;
                    color: #ffffff;
                    background: nth($color, 4);
                }
                &.active {
                    font-weight: 700;
                    background-color: nth($color, 9);
                }
            }
        }
    }
}