.section.contact {
    padding: 60px 0;
    background: nth($color, 3);

    .bg {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        img {
            display: block;
            width: 100%;
        }
    }

    .wrap {
        z-index: 2;
        height: 100%;

        .content {

            .form {
                width: 50%;
                margin: 0 auto;
                color: $mainBg;

                .description {
                    margin-bottom: 1em;
                    a {
                        color: $mainBg;
                        text-decoration: underline;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                div.gform_wrapper {
                    li.gfield,
                    li.gfield.gfield_error {
                        position: relative;

                        #{$all-text-inputs} {
                            @include transition(opacity .4s);
                            color: nth($color, 7);
                            opacity: .5;
                            &:hover {
                                opacity: .75;
                            }
                            &:focus {
                                opacity: 1;
                            }
                        }
                    }

                    .gform_body {
                        margin-bottom: rem(20);
                        textarea {
                            height: 150px;
                        }
                    }

                    .gform_footer {
                        text-align: center;
                        margin: 0;
                        padding: 0;
                        width: auto;
                        input[type=submit] {
                            margin: 15px 0 0;
                        }
                    }
                }
            }

            .gform_confirmation_wrapper {
                text-align: center;
                color: $mainBg;
                font-size: 25px;
                background: $footerBg;
                padding: 40px;
            }
        }
    }

    @include media($brTablet) {
        height: auto;
        padding: 40px 0;

        .bg {
            @include cover;
            bottom: 0;
            img {
                display: none;
            }
        }

        .wrap {
            .content {
                @include transform(none);
                position: relative;
                top: auto;
                left: auto;

                .form {
                    width: 100%;
                }
            }
        }
    }

}