.woocommerce ul.products li.product,
.woocommerce-page ul.products li.product {
  &:before {
    display: none;
  }
}

h2.woocommerce-loop-product__title:after {
  content: 'View Product';
  display: block;
  clear: both;
  width: 75%;
  background: nth($color, 1);;
  text-align: center;
  margin: 20px auto 0;
  padding: 10px;
  color: white;
  transition: all 300ms;
  &:hover {
    background: nth($color, 2);;
  }
}