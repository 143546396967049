.section.cta {
    padding: 50px 0;
    background: nth($color, 5);
    color: #000;
    .text {
        margin-right: rem(140);
        .title {
            margin: 0;
            font-weight: 300;
            font-size: rem(36);
            text-transform: uppercase;
        }
    }

    .more {
        @include align-center-v(2);
        right: 0;
        .btn {
            text-transform: uppercase;
            border-color: #000;
            background: transparent;
            color: #000;
            &:hover {
                background: #ffffff;
            }
        }
    }

    @include media($brTablet) {
        .text {
            margin-right: 0;
            margin-bottom: 1em;
            text-align: center;
        }

        .more {
            @include transform(none);
            position: relative;
            top: auto;
            text-align: center;
        }
    }
}