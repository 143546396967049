.flexible-content {
    clear: both;
    padding: 20px 0;
    &.boxes {
        .items {
            .item {
                position: relative;
                z-index: 1;
                margin-bottom: 20px;
                padding: 1px;
                border: 2px solid rgba(#000, .1);
                figure {
                    @include rel-height(60%);
                    @include cover;
                    + i {
                        position: absolute;
                        top: -15px;
                        left: -15px;
                        display: block;
                        width: 60px;
                        height: 60px;
                        line-height: 60px;
                        background: #fff;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 42px;
                    }
                }

                .content {
                    padding: 20px;
                    .title {
                        margin-top: 0;
                    }
                    .desc {
                        margin-bottom: 20px;
                        p {
                            &:last-child {
                                margin: 0;
                            }
                        }
                    }

                }
            }
        }

        @include media(min-width 768px) {
            .items {
                @include display(flex);
                @include flex-wrap(wrap);
                margin-right: -10px;
                margin-left: -10px;

                .item {
                    @include flex(0 0 calc(50% - 20px));
                    margin: 0 10px 20px;
                }
            }
        }

        @include media(min-width 1024px) {
            .items {
                .item {
                    @include flex(0 0 calc(33.33% - 20px));
                }
            }
        }
    }

    &.accordion {

    }

    &.tabs {

    }

    &.buttons {

    }
}