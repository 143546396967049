.post-content {
    #team {
        @include clearfix;
        padding: 40px 0;
        .member {
            @include span-columns(6);
            @include omega(2n);
            @include clearfix;
            position: relative;
            z-index: 1;
            display: block;
            margin-bottom: 2em;
        }


        .thumbnail {
            @include span-columns(4);
            @include rel-height(100%);
            @include cover;
            display: block;
        }

        .details {
            @include span-columns(8);
            header {
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid nth($color, 10);
            }
            .name {
                margin: 0;
                font-size: 1.25em;
                font-weight: 700;
                text-transform: uppercase;
            }
            .meta {
                p {
                    font-size: em(14px);
                    margin: 0;
                    font-weight: 700;
                }
            }

            .excerpt {
                font-size: em(14px);
            }

            .extra {
                i {
                    font-size: 1.25em;
                }

                .phone,
                .email {
                    position: relative;
                    z-index: 1;
                    padding-left: 30px;
                    i {
                        position: absolute;
                        left: 0;
                    }
                }

                .social {
                    li {
                        display: inline-block;
                        margin-right: 10px;
                    }
                }
            }

            .more {
                padding-top: 1em;
                font-weight: 700;
                text-transform: uppercase;
            }
        }

        @include media($brLargeDesktop) {
        }

        @include media($brDesktop) {
            .member {
                @include fill-parent;
            }
        }
        @include media($brMobile) {
            .member {
                .thumbnail,
                .details {
                    @include fill-parent;
                }
                .thumbnail {
                    margin-bottom: rem(20);
                }
            }
        }
    }
}